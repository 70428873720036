import React, {useEffect, useState} from 'react';
import {
    disableEventMonitor, enableEventMonitor, getEventMonitor,

} from "../api/monitor";
import {Switch, Table, Tag, Tooltip} from "antd";

const EventMonitor = ({permissions}) => {
    const [pagination, setPagination] = useState({
        page: 1,
        pageSize: 1000,
        total: 0
    })
    const [monitors, setMonitors] = useState<Array<any>>([]);

    useEffect(() => {
        async function fetchData() {
            const data = await getEventMonitor(pagination.page, pagination.pageSize);
            setMonitors(data["monitors"])
            setPagination({...pagination, total: data["total"]})
        }

        fetchData();
    }, [pagination]);

    const renderMonitorSwitch = (enabled, monitor, index) => {
        return (
            <Switch
                checkedChildren="开启"
                unCheckedChildren="关闭"
                defaultChecked={enabled}
                disabled={!permissions.canWriteMonitor}
                onChange={() => handleMonitorSwitchChange(index)}
            />
        )
    }

    const handleMonitorSwitchChange = async (index: number) => {
        const monitor = monitors.at(index)
        const enable = monitor.enabled
        if (enable) {
            await disableEventMonitor(monitor.id)
        } else {
            await enableEventMonitor(monitor.id)
        }
        monitor.enabled = !enable
    }

    const renderTriggerTime = (monitor) => {
        if (monitor.latest_trigger_time === -1 || monitor.latest_trigger_time == null) {
            return <></>
        } else {
            const timePassed = new Date().getTime() - monitor.latest_trigger_time
            if (timePassed > 24 * 60 * 60 * 1000) {
                return <></>
            } else {
                const message = timePassed < 5 * 60 * 1000 ? {
                    time: "5分钟",
                    color: "red"
                } : timePassed < 60 * 60 * 1000 ? {
                    time: "1小时",
                    color: "orange"
                } : {
                    time: "24小时",
                    color: "yellow"
                }
                const lastTriggerTime = new Date(monitor.latest_trigger_time)
                return (
                    <Tooltip placement="top" title={"最近触发时间: " + lastTriggerTime.toLocaleString()}>
                        <Tag color={message.color}>
                            {message.time} 内触发过
                        </Tag>
                    </Tooltip>
                )
            }
        }
    }

    const renderContractName = (_, monitor, index) => {
        return(<Tooltip placement="top" title={monitor.contract_address}>
            <Tag>
                {monitor.contract_name}
            </Tag>
        </Tooltip>)
    }

    const renderEventParams = (_, monitor) => {
        return (
            <>
                {monitor.params.map((p) => {
                    return (
                        <Tag key={p}>
                            {p}
                        </Tag>
                    );
                })}
            </>
        )
    }

    const col = [
        {title: '链', dataIndex: 'chain', width: '5%'},
        {title: '合约名称', dataIndex: 'contract_name', width: '10%', render: renderContractName},
        {title: 'Event名称', dataIndex: 'name',  width: '10%'},
        {title: 'Event参数', dataIndex: 'params',  width: '10%', render: renderEventParams},
        {title: '操作', dataIndex: 'enabled', render: renderMonitorSwitch},
        {
            title: '创建时间',
            dataIndex: 'created_time',
            render: (monitor) => <div>{monitor.substring(0, 10)}</div>
        },
        {title: '', dataIndex: 'latest_trigger_time', render: renderTriggerTime}
    ]

    return (
        <Table
            dataSource={monitors}
            columns={col}
            pagination={{
                pageSize: pagination.pageSize,
                onChange: (page, size) => {
                    setPagination({
                        ...pagination, page, pageSize: size
                    })
                },
                total: pagination.total,
                showSizeChanger: true
            }}
            rowKey={monitor => monitor.id}
        />
    );
};

export default EventMonitor;
