import {commonApi} from "../axios";

interface Response<T> {
    success: boolean,
    result: T,
}

const login = async (message: string, signature: string) => {
    const response: Response<any> = await commonApi.post(
        '/monitor/base/login/',
        {message, signature}
    )
    if (response.success) {
        localStorage.setItem('cobo-siwe-message', message);
        localStorage.setItem('cobo-siwe-signature', signature);
        localStorage.setItem('is-login', "true");
    }
    return response.success
}

const logout = async () => {
    const response: Response<any> = await commonApi.post(
        '/monitor/base/logout/'
    )
    if (response.success) {
        localStorage.removeItem('is-login');
        localStorage.removeItem('cobo-siwe-message');
        localStorage.removeItem('cobo-siwe-signature');
    }
    return response.success
}

const getMonitor = async (current_page: number, page_size: number) => {
    const response: Response<Array<any>> = await commonApi.get(
        '/monitor/generic/get_monitors/',
        {params: {current_page, page_size}}
    );
    return response.result
}

const getEventMonitor = async (current_page: number, page_size: number) => {
    const response: Response<Array<any>> = await commonApi.get(
        '/monitor/event/get_event_monitors/',
        {params: {current_page, page_size}}
    );
    return response.result
}

const getWalletPermission = async () => {
    const response: Response<any> = await commonApi.get(
        '/monitor/generic/get_wallet_permission/'
    );
    return response.result
}

const getRules = async (monitor_id: string) => {
    const response: Response<Array<any>> = await commonApi.get(
        '/monitor/generic/get_rules_by_monitor/',
        {params: {monitor_id}}
    );
    return response.result
}

const getEventRules = async (monitor_id: string) => {
    const response: Response<Array<any>> = await commonApi.get(
        '/monitor/event/get_event_rules_by_monitor/',
        {params: {monitor_id}}
    );
    return response.result
}

const getContractInfo = async (chain: string, address: string, implementation_address?: string) => {
    const response: Response<any> = await commonApi.get(
        '/monitor/generic/get_contract_info/',
        {params: {chain, address, implementation_address}}
    );
    return response.result
}

const getSupportedChains = async () => {
    const response: Response<Array<string>> = await commonApi.get(
        '/monitor/generic/get_supported_chains/'
    );
    return response.result
}

const enableRule = async (rule_id: string) => {
    const response: Response<any> = await commonApi.post(
        '/monitor/generic/enable_rule/',
        {"rule_id": rule_id}
    );
    return response.success
}
const disableRule = async (rule_id: string) => {
    const response: Response<any> = await commonApi.post(
        '/monitor/generic/disable_rule/',
        {"rule_id": rule_id}
    );
    return response.success
}

const enableEventRule = async (rule_id: string) => {
    const response: Response<any> = await commonApi.post(
        '/monitor/event/enable_event_rule/',
        {"rule_id": rule_id}
    );
    return response.success
}
const disableEventRule = async (rule_id: string) => {
    const response: Response<any> = await commonApi.post(
        '/monitor/event/disable_event_rule/',
        {"rule_id": rule_id}
    );
    return response.success
}

const updateRule = async (rule_id: string, opcode: number, value: number,
                          notification: string, urgency: number, period?: number) => {
    const response: Response<any> = await commonApi.post(
        '/monitor/generic/update_rule/',
        {"rule_id": rule_id, value, opcode, notification, urgency, period}
    );
    return response.success
}

const updateEventRule = async (rule_id: string, opcode: number, value: number,
                          notification: string, urgency: number, period?: number) => {
    const response: Response<any> = await commonApi.post(
        '/monitor/event/update_event_rule/',
        {"rule_id": rule_id, value, opcode, notification, urgency, period}
    );
    return response.success
}

const createRule = async (monitor_id: string, field: string, opcode: number,
                          value: number, notification: string, urgency: number, period?: number) => {
    const response: Response<any> = await commonApi.post(
        '/monitor/generic/create_rule/',
        {"monitor_id": monitor_id, field, value, opcode, notification, urgency, period}
    );
    return response.success
}

const createEventRule = async (monitor_id: string, field: string, opcode: number,
                          value: number, notification: string, urgency: number, period?: number) => {
    const response: Response<any> = await commonApi.post(
        '/monitor/event/create_event_rule/',
        {"monitor_id": monitor_id, field, value, opcode, notification, urgency, period}
    );
    return response.success
}

const disableMonitor = async (monitor_id: string) => {
    const response: Response<any> = await commonApi.post(
        '/monitor/generic/disable_monitor/',
        {"monitor_id": monitor_id}
    );
    return response.success
}

const enableMonitor = async (monitor_id: string) => {
    const response: Response<any> = await commonApi.post(
        '/monitor/generic/enable_monitor/',
        {"monitor_id": monitor_id}
    );
    return response.success
}

const disableEventMonitor = async (monitor_id: string) => {
    const response: Response<any> = await commonApi.post(
        '/monitor/event/disable_event_monitor/',
        {"monitor_id": monitor_id}
    );
    return response.success
}

const enableEventMonitor = async (monitor_id: string) => {
    const response: Response<any> = await commonApi.post(
        '/monitor/event/enable_event_monitor/',
        {"monitor_id": monitor_id}
    );
    return response.success
}

export {
    login,
    logout,
    getMonitor,
    getEventMonitor,
    getContractInfo,
    getSupportedChains,
    enableRule,
    disableRule,
    disableMonitor,
    enableMonitor,
    updateRule,
    createRule,
    getRules,
    getWalletPermission,
    disableEventMonitor,
    enableEventMonitor,
    getEventRules,
    enableEventRule,
    disableEventRule,
    createEventRule,
    updateEventRule
}
