import axios from 'axios';

export const commonApi = axios.create({
    baseURL: 'https://api.argus.cobo.com/v1',
    timeout: 60000,
});

commonApi.interceptors.request.use(
    (config) => {
        const message = localStorage.getItem('cobo-siwe-message');
        if (message && config.headers) {
            config.headers['cobo-siwe-message'] = JSON.stringify(message);
        }
        const signature = localStorage.getItem('cobo-siwe-signature');
        if (signature && config.headers) {
            config.headers['cobo-siwe-signature'] = signature;
        }
        return config;
    },
    (error) => Promise.reject(error));

commonApi.interceptors.response.use(
    (response) => response.data,
    (error) => {
        if (error.response.status === 401) {
            localStorage.removeItem('cobo-siwe-message');
            localStorage.removeItem('cobo-siwe-signature');
            localStorage.removeItem('is-login');
        }
        return Promise.reject(error);
    });
