import React, {useEffect, useState} from "react";
import {Button, Switch, Table, Tag, Tooltip} from "antd";
import {
    createRule,
    disableMonitor,
    disableRule,
    enableMonitor,
    enableRule,
    getMonitor,
    getRules,
    updateRule
} from "../api/monitor";
import {EditableProTable, ProColumns} from "@ant-design/pro-table";
import {InfoCircleFilled} from "@ant-design/icons";

type Rule = {
    id: React.Key;
    field: string;
    opcode: string;
    value: number;
    period: number;
    enabled: boolean;
    notification: string;
    urgency: string
};

const ContractMonitor = ({permissions}) => {
    const [pagination, setPagination] = useState({
        page: 1,
        pageSize: 1000,
        total: 0
    })
    const [monitors, setMonitors] = useState<Array<any>>([]);

    useEffect(() => {
        async function fetchData() {
            const data = await getMonitor(pagination.page, pagination.pageSize);
            setMonitors(data["monitors"])
            setPagination({...pagination, total: data["total"]})
        }
        fetchData();
    }, []);
    const renderMonitorSwitch = (enabled: boolean, monitor: any, index: number) => {
        return (
            <Switch
                checkedChildren="开启"
                unCheckedChildren="关闭"
                defaultChecked={enabled}
                disabled={!permissions.canWriteMonitor}
                onChange={() => handleMonitorSwitchChange(index)}
            />
        )
    }

    const renderTriggerTime = (_: any, monitor: any) => {
        if (monitor.latest_trigger_time === -1 || monitor.latest_trigger_time === null) {
            return <></>
        } else {
            const timePassed = new Date().getTime() - monitor.latest_trigger_time
            if (timePassed > 24 * 60 * 60 * 1000) {
                return <></>
            } else {
                const message = timePassed < 5 * 60 * 1000 ? {
                    time: "5分钟",
                    color: "red"
                } : timePassed < 60 * 60 * 1000 ? {
                    time: "1小时",
                    color: "orange"
                } : {
                    time: "24小时",
                    color: "yellow"
                }
                const lastTriggerTime = new Date(monitor.latest_trigger_time)
                return (
                    <Tooltip placement="top" title={"最近触发时间: " + lastTriggerTime.toLocaleString()}>
                        <Tag color={message.color}>
                            {message.time} 内触发过
                        </Tag>
                    </Tooltip>
                )
            }
        }
    }

    const handleMonitorSwitchChange = async (index: number) => {
        const monitor = monitors.at(index)
        const enable = monitor.enabled
        if (enable) {
            await disableMonitor(monitor.id)
        } else {
            await enableMonitor(monitor.id)
        }
        monitor.enabled = !enable
    }

    const renderData = (_: any, monitor: any) => {
        const data = monitor.data_fields.map((field) => {
            return {
                name: field,
                value: monitor.latest_data[field]
            }
        })
        return <>
            {data.map((c) => {
                return (
                    <Tooltip placement="top" title={c.value && c.value.toLocaleString()}>
                        <Tag key={c.value}>
                            {c.name}
                        </Tag>
                    </Tooltip>
                );
            })}
        </>;
    }
    const col = [
        {title: '名称', dataIndex: 'name', key: 'name', width: '20%'},
        {title: '链', dataIndex: 'chain', key: 'chain', width: '5%'},
        {title: '数据', key: 'data', dataIndex: 'data', width: '50%', render: renderData},
        {
            title: '创建时间',
            dataIndex: 'created_time',
            key: 'created_time',
            render: (monitor) => <div>{monitor.substring(0, 10)}</div>
        },
        {title: '操作', dataIndex: 'enabled', key: 'action', render: renderMonitorSwitch},
        {title: '', dataIndex: 'latest_trigger_time', key: 'latest_trigger_time', render: renderTriggerTime}
    ]

    const handleRuleSwitchChange = async (rule) => {
        const enable = rule.enabled
        if (enable) {
            await disableRule(rule.id)
        } else {
            await enableRule(rule.id)
        }
    }

    const renderRuleSwitch = (enabled: boolean, rule: any) => {
        return (
            <Switch
                checkedChildren="开启"
                unCheckedChildren="关闭"
                defaultChecked={enabled}
                disabled={!permissions.canWriteRule}
                onChange={() => handleRuleSwitchChange(rule)}
            />
        )
    }
    const urgencyMap = {
        0: 'High',
        1: 'Medium',
        2: 'Low'
    }

    const urgencyStringToNumber = {
        'High': 0,
        'Medium': 1,
        'Low': 2
    }

    const opcodeMap = {
        0x10: ["<", "Decrease"],
        0x11: [">", "Increase"],
        0x12: ["="],
        0x13: ["<=", "Decrease"],
        0x14: [">=", "Increase"],
        0x15: ["!="],
        0x20: ["Changed"]
    }

    const opcodeToString = {
        "<": 0x10,
        "<=": 0x13,
        ">": 0x11,
        ">=": 0x14,
        "=": 0x12,
        "!=": 0x15,
        "Decrease": 0x10,
        "Increase": 0x11,
        "Changed": 0x20,
    }
    const getRulesByMonitor = async (monitorId) => {
        const rules = await getRules(monitorId)
        return rules.map((r: any) => {
                return {
                    id: r.id,
                    field: r.field,
                    opcode: opcodeMap[r.opcode][r.period === 0 ? 0 : 1],
                    value: r.value,
                    period: r.period,
                    enabled: r.enabled,
                    notification: r.notification,
                    latest_trigger_time: r.latest_trigger_time,
                    urgency: urgencyMap[r.urgency],
                }
            }
        )
    }

    const [editableKeys, setEditableRowKeys] = useState<React.Key[]>([]);
    const [rules, setRules] = useState<Rule[]>([]);
    const [expandedRows, setExpandedRows] = useState([])
    const expandedRowRender = (monitor) => {
        const columns: ProColumns<Rule>[] = [
            {
                title: '监控字段', dataIndex: 'field', key: 'field', width: '15%',
                editable: (_, rule) => {
                    for (let i = 0; i < rules.length; i++) {
                        if (rules[i].id === rule.id) {
                            return false;
                        }
                    }
                    return true;

                },
                valueEnum: monitor.data_fields.reduce((k, v) => ({
                    ...k,
                    [v]: {"text": v}
                }), {})
            },
            {
                title: '比较方式', dataIndex: 'opcode', key: 'opcode', width: '5%',
                render: (node, rule, index, _) => {
                    return (<div>{rule.opcode}</div>)
                },
                valueType: 'select',
                valueEnum: {
                    "<": {text: "<"},
                    "<=": {text: "<="},
                    ">": {text: ">"},
                    ">=": {text: ">="},
                    "Increase": {text: "Increase"},
                    "Decrease": {text: "Decrease"},
                    "=": {text: "="},
                    "!=": {text: "!="}
                }
            },
            {title: '值', dataIndex: 'value', key: 'value', width: '5%'},
            {
                title: '变化周期(秒)', dataIndex: 'period', key: 'period', width: '5%', render: (period: number) => {
                    if (period > 0) {
                        return (
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div>{period}</div>
                                <Tooltip placement="top" title="周期内变化趋势的监控,触发报警后开关不会自动关闭,只能手动关闭, 或者调整参数以加强报警条件">
                                    <InfoCircleFilled style={{fontSize: '16px', color: '#ffa500'}}/>
                                </Tooltip>
                            </div>
                        )
                    } else {
                        return (<div>{period}</div>)
                    }
                }
            },
            {title: "通知", dataIndex: 'notification', key: 'notification', width: '43%'},
            {title: "开关", dataIndex: 'enabled', key: 'enabled', readonly: true, render: renderRuleSwitch, width: '5%'},
            {
                title: '风险等级', dataIndex: 'urgency', key: 'urgency', width: '8%',
                render: (node, rule, index, _) => {
                    const fontColor = {
                        "High": 'red',
                        "Medium": 'orange',
                        "Low": 'green'
                    }
                    return (
                        <Tooltip placement="top" title="">
                            <div style={{color: fontColor[rule.urgency]}}>{rule.urgency}</div>
                        </Tooltip>
                    )
                },
                valueType: 'select',
                valueEnum: {
                    "High": {text: "High"},
                    "Medium": {text: "Medium"},
                    "Low": {text: "Low"},
                }
            },
            {
                title: '操作',
                valueType: 'option',
                width: '5%',
                render: (text, rule, _, action) => [
                    <Button
                        key="editable"
                        onClick={() => {
                            if (permissions.canWriteRule) {
                                action?.startEditable?.(rule.id)
                            }
                        }}>
                        修改
                    </Button>,
                ],
            },

            {
                title: '',
                editable: false,
                dataIndex: 'latest_trigger_time',
                key: 'latest_trigger_time',
                render: renderTriggerTime
            }
        ];

        return <EditableProTable<Rule>
            rowKey="id"
            columns={columns}

            request={async () => ({
                data: await getRulesByMonitor(monitor.id),
                success: true,
            })}
            recordCreatorProps={{
                position: 'bottom',
                disabled: !permissions.canWriteRule,
                creatorButtonText: "新增一条报警规则",
                record: () => ({
                    id: (Math.random() * 1000000).toFixed(0),
                    field: monitor.data_fields[0],
                    opcode: ">",
                    value: 0,
                    period: 0,
                    enabled: true,
                    notification: "",
                    urgency: 'High'

                }),
            }}
            pagination={false}
            value={rules}
            onChange={setRules}
            editable={{
                type: 'single',
                editableKeys,
                onSave: async (rowKey, data: Rule, _: Rule) => {
                    const newData = [...rules]
                    const index = newData.findIndex(item => data.id === item.id)
                    if (index > -1) {
                        await updateRule(rowKey.toString(), opcodeToString[data.opcode],
                            data.value, data.notification, urgencyStringToNumber[data.urgency], data.period);
                    } else {
                        await createRule(monitor.id, data.field, opcodeToString[data.opcode],
                            data.value, data.notification, urgencyStringToNumber[data.urgency], data.period)
                    }
                    const newRules = await getRulesByMonitor(monitor.id)
                    setRules([...newRules])
                },
                onChange: setEditableRowKeys,
                actionRender: (row, config, defaultDoms) => {
                    return [defaultDoms.save, defaultDoms.cancel]
                }
            }}
        />;
    };
    const onExpand = async (expanded, monitor) => {
        if (expanded) {
            const rules = await getRulesByMonitor(monitor.id)
            setRules(rules)
            setExpandedRows([monitor.id])
        } else {
            setExpandedRows([])
        }
    }
    return (
        <Table
            dataSource={monitors}
            columns={col}
            pagination={{
                pageSize: pagination.pageSize,
                onChange: (page, size) => {
                    setPagination({
                        ...pagination, page, pageSize: size
                    })
                },
                total: pagination.total,
                showSizeChanger: true
            }}
            expandable={{
                expandedRowRender: (monitor => expandedRowRender(monitor)),
                expandedRowKeys: expandedRows,
                onExpand:(expanded, monitor) => onExpand(expanded, monitor)
            }}
            rowKey={monitor => monitor.id}
        />
    )
}
export default ContractMonitor
