import React, {useEffect, useState} from 'react';
import Web3 from 'web3';
import detectEthereumProvider from '@metamask/detect-provider';
import './App.css';
import {SiweMessage} from 'siwe';
import moment from 'moment';
import {getWalletPermission, login, logout} from "./api/monitor";
import 'antd/dist/antd.css';

import {Button, Layout, Menu, Modal} from 'antd';
import {Link, Route, Routes, useLocation} from "react-router-dom";
import ContractMonitor from "./pages/ContractMonitor";
import EventMonitor from "./pages/EventMonitor";

const {Header, Content} = Layout;

function App() {
    const eth = window.ethereum;

    console.log(eth)
    if (eth) {
        //detect account change
        // @ts-ignore
        eth.on('accountsChanged', () => {
            localStorage.removeItem('cobo-siwe-message');
            localStorage.removeItem('cobo-siwe-signature');
        })
        // @ts-ignore
        eth.on('chainChanged', () => {
            localStorage.removeItem('cobo-siwe-message');
            localStorage.removeItem('cobo-siwe-signature');
        })
    }
    const domain = window.location.host;
    const origin = window.location.origin;
    const createSiweMessage = async (address: string, statement: string, chainId: number) => {
        const now = moment();
        const message = new SiweMessage({
            domain,
            address,
            statement,
            uri: origin,
            version: '1',
            chainId: chainId,
            nonce: now.valueOf().toString(),
            notBefore: now.toISOString(),
            expirationTime: now.add(30, 'days').toISOString(),
        });
        return message.prepareMessage();
    };

    const connect = async () => {
        const eth: any = await detectEthereumProvider();
        const web3 = new Web3(eth);
        await eth.request({
            method: 'wallet_requestPermissions',
            params: [{eth_accounts: {}}],
        });
        const accounts = await web3.eth.getAccounts();
        const chainId = await web3.eth.getChainId();
        if (accounts.length > 0) {
            const message = await createSiweMessage(
                accounts[0],
                'Sign in Cobo monitor with Ethereum',
                chainId,
            );
            const signature = await web3.eth.personal.sign(message, accounts[0], 'pass');
            const success = await login(message, signature);
            if (success) {
                setIsLogin(true)
                await queryPermission()
            }
        }

    };

    const queryPermission = async () => {
        const READ_ONLY = 1
        const WRITE_RULE = 1 << 1
        const WRITE_MONITOR = 1 << 2
        const permission: number = await getWalletPermission();
        const canRead = (permission & READ_ONLY) > 0
        const canWriteRule = (permission & WRITE_RULE) > 0
        const canWriteMonitor = (permission & WRITE_MONITOR) > 0
        setPermissions({
            canRead, canWriteRule, canWriteMonitor
        })
    }

    const [permissions, setPermissions] = useState({
        canRead: false,
        canWriteMonitor: false,
        canWriteRule: false,
    })

    useEffect(() => {
        queryPermission();
    }, []);

    const disconnect = async () => {
        setIsModalVisible(true)
    }

    const renderHeader = () => {
        const isLogin = localStorage.getItem("is-login") === "true"
        return <div style={{display: 'flex', height: '100%', justifyContent: 'flex-end', alignItems: 'center'}}>
            <Button onClick={isLogin ? disconnect : connect}>{isLogin ? "Logout" : "Login"}</Button>
        </div>;
    }

    const confirmLogout = async () => {
        const success = await logout()
        if (success) {
            setIsLogin(false)
        }
        setIsModalVisible(false)
    }

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isLogin, setIsLogin] = useState<boolean>(localStorage.getItem('is-login') !== null);

    const location = useLocation()
    const menuItems = [
        {key: "contract", label: (<Link to='/contract'>合约监控</Link>), path: '/contract'},
        {key: "event", label: (<Link to='/event'>event监控</Link>), path: '/event'},
    ]
    const [selectedKey, setSelectedKey] = useState("contract")
    useEffect(() => {
        let key = "contract"
        if (location.pathname.startsWith('/event')) {
            key = "event"
        }
        setSelectedKey(key)
    }, [location])
    return (
        <Layout>
            <Header style={{position: 'fixed', zIndex: 1, width: '100%', display: 'flex', alignItems: 'end'}}>
                <Menu style={{width: '95%'}}
                      theme="dark"
                      mode="horizontal"
                      selectedKeys={[selectedKey]}
                      items={menuItems}
                />
                {renderHeader()}
            </Header>
            <Layout>
                <Content style={{paddingTop: 60}}>
                    {
                        isLogin ?
                            <Routes>
                                <Route path="/contract" element={<ContractMonitor permissions={permissions}/>}/>
                                <Route path="/event" element={<EventMonitor permissions={permissions}/>}/>
                                <Route path="/" element={<ContractMonitor permissions={permissions}/>}/>
                            </Routes>
                            :
                            <div
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    padding: 200
                                }}>
                                <p style={{fontSize: 'xx-large'}}>Please login with ethereum</p>
                            </div>
                    }
                </Content>
            </Layout>
            <Modal title="Logout"
                   visible={isModalVisible}
                   onOk={confirmLogout}
                   onCancel={() => setIsModalVisible(false)}>
                <p>Confirm logout?</p>
            </Modal>
        </Layout>
    );
}

export default App;
